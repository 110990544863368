import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../app/store/app.reducers';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { getUser } from '../../app/store/selectors/user.selectors';
import { combineLatestWith, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class KioskModeService {
    isKioskMode$ = new BehaviorSubject<boolean>(false);

    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute
    ) {
        const user$ = this.store.select(getUser);
        const kioskMode$ = this.activatedRoute.queryParams;

        user$
            .pipe(combineLatestWith(kioskMode$))
            .pipe(map(([user, params]) => !user && !!params.kioskMode))
            .subscribe((v) => this.isKioskMode$.next(v));
    }
}
